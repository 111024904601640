.page-faq {
	height: 100%;
	padding: 0 35px;

	@include max-width($scr-md) {
		padding: 0 15px;
	}

	.faq {
		&__form {
			width: 100%;
			max-width: 1280px;
		}

		&__container {
			margin: 15px 0;
			gap: 15px;
			display: flex;
		}

		&__name {
			margin: 0;
			color: $c-blue;
			font-size: $text-size-m;
			font-weight: $text-weight-semibold;
		}

		&__description {
			margin: 5px 0;
			color: $c-dark-grey;
			font-size: $text-size-xs;
		}

		&__title {
			margin: 0;
			color: $c-black;
			font-weight: $text-weight-semibold;
		}

		&__date {
			margin: 5px 0;
			color: $c-light-grey;
			font-size: $text-size-xs;
		}

		&__box {
			flex: 1;
			padding: 15px;
			border-radius: 8px;
			background-color: $c-white;
			box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
		}

		&__box-header {
			min-height: 40px;
			display: flex;
			flex-direction: column;
		}

		&__wrapper {
			margin-top: 10px;
			gap: 35px;
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;

			&--upload {
				padding-top: 20px;
				border-top: 1px dashed $c-disabled;
			}
		}

		&__box-body {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			gap: 20px 35px;

			.app-input,
			.app-select-input,
			.app-select-status,
			.app-calendar-input {
				@include min-width($scr-sm) {
					width: calc(50% - 17.5px);
				}
			}

			.app-input--multiline {
				width: 100%;
			}
		}

		&__button-container {
			width: 100%;
			max-width: 350px;
			margin-top: 25px;
			margin-left: auto;
			gap: 15px;
			display: flex;

			.app-button {
				flex: 1;

				&--outline {
					width: auto;
					border: 0;
					background-color: transparent;

					.app-button__label {
						color: $c-primary;
					}
				}
			}
		}
	}
}
