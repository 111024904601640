.app-page {
	height: 100%;
}

.app-advance-search-form {
	.advance-form {
		//
		&__inputs {
			gap: 15px;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;

			.app-input,
			.app-select-input,
			.app-calendar-input {
				width: calc(50% - 7.5px);

				@include max-width($scr-sm) {
					width: 100%;
				}
			}
		}

		&__button-container {
			padding: 20px 0 0;
			display: flex;
			align-items: center;
			justify-content: center;

			.app-button {
				width: auto;
				margin: 0 10px;
				padding: 10px 20px;

				&--outline {
					border: 0;

					.app-button__label {
						color: $c-primary;
					}
				}
			}
		}
	}
}

.app-table-menu {
	.MuiMenu-paper {
		border-radius: 8px;
		box-shadow: 0px 4px 12px 0px rgba(224, 229, 234, 0.6);

		.MuiButtonBase-root {
			padding: 10px 45px 10px 15px;
			color: $c-primary;
			font-size: $text-size-s;
		}

		.app-table-menu__remove {
			color: $c-red;
		}

		.app-table-menu__icon {
			&--loading {
				animation: rotate 5s infinite;
			}
		}
	}

	&__icon {
		width: 20px;
		height: 20px;
		margin: 0 8px;
	}
}

@keyframes loading {
	to {
		stroke-dashoffset: 1000;
	}
}

@keyframes rotate {
	to {
		transform: rotate(-360deg);
	}
}
