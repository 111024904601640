.page-users {
	height: 100%;

	.users {
		height: 100%;

		&__results {
			padding: 10px 0;
			margin: 10px 0;
			border-bottom: 1px dashed $c-disabled;
		}

		&__text {
			margin: 0;
			font-weight: $text-weight-semibold;
		}
	}
}