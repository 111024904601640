.page-reset-password {
	background-color: $c-background;

	.reset-password {
		min-height: 100vh;

		.main {
			width: 100%;
			min-height: 100vh;
			display: flex;
			flex-direction: row;
			align-items: stretch;

			@include max-width($scr-sm) {
				flex-direction: column;
			}

			&__content {
				width: 65%;

				&--background {
					width: 35%;
					min-width: 400px;
					min-height: 100%;
					background-color: rgb(3, 149, 150);
					background: linear-gradient(157deg, rgba(3, 149, 150, 1) 0%, rgba(9, 166, 148, 1) 40%, rgba(22, 202, 144, 1) 100%);
					display: flex;
					align-items: center;
					justify-content: flex-end;

					@include max-width($scr-sm) {
						display: none;
					}
				}

				&--form {
					width: 100%;
					min-height: 100vh;
					max-width: 650px;
					padding: 20px;
					display: flex;
					justify-content: center;
					flex-direction: column;

					@include min-width($scr-sm) {
						padding: 20px 20px 20px calc(5.5vw + 20px);
					}
				}
			}

			&__logo {
				width: 100%;
				margin-right: -6.5vw;

				img {
					width: 100%;
					max-width: 650px;
				}
			}

			&__form {
				margin-top: auto;
				margin-bottom: auto;
			}

			&__title {
				margin: 0 0 35px;
				color: $c-primary;
				font-weight: $text-weight-semibold;
				font-size: $text-size-xxl;
			}

			&__guidence {
				margin: 10px 0 25px;
			}

			&__description {
				margin: 0 0 10px;
				color: $c-dark-grey;
				font-size: $text-size-s;
				display: flex;
				flex-direction: row;
				align-items: center;
			}

			&__icon {
				width: 12.5px;
				height: 12.5px;
				margin: 0 5px 0 0;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			&__button-container {
				margin: 50px 0 0;
				max-width: 250px;
			}

			&__copyright {
				color: $c-light-grey;
				font-size: $text-size-xs;
			}

			.app-input {
				margin-bottom: 20px;
			}
		}
	}
}
