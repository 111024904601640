@import "./app-calendar";
@import "./app-years";
@import "./app-months";
@import "./app-weekdays";

.app-calendar-input {
	position: relative;
	width: 100%;
	display: flex;

	&__label {
		color: $c-dark-grey;
		font-size: $text-size-s;
		font-weight: $text-weight-semibold;
	}

	&__required {
		color: $c-red;
		line-height: 0;
	}

	&__wrapper {
		pointer-events: auto;
		cursor: pointer;
	}

	&__icon {
		padding: 7px;
		width: 25.5px;
		height: 25.5px;
		pointer-events: auto;
		cursor: pointer;
	}

	.MuiFormControl-root {
		width: 100%;
		pointer-events: none;

		.MuiInputBase-root {
			color: $c-black;
			padding-right: 0;
			border-radius: 0;
			font-weight: $text-weight-normal;
		}

		.MuiInputBase-input {
			padding: 10px 0;
			cursor: pointer;
		}

		.Mui-disabled {
			-webkit-text-fill-color: inherit;
		}

		.MuiOutlinedInput-notchedOutline {
			border: 0 !important;
			border-bottom: 1px solid $c-disabled !important;
		}

		.MuiInputAdornment-root {
			pointer-events: none;
		}

		.MuiFormHelperText-root {
			margin: 3px 0 0;
			color: $c-red;
			font-weight: $text-weight-normal;
			font-size: $text-size-xs;
		}
	}

	&--disabled {
		background-color: #f8f8f8;

		.MuiFormControl-root {
			.Mui-disabled {
				&:not(.MuiFormHelperText-root) {
					-webkit-text-fill-color: rgba(0, 0, 0, 0.38);
				}
			}
		}
	}
}

.app-calendar-modal {
	padding: 10px;

	.calendar-modal {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		&__button-container {
			width: 100%;
			margin: 10px 0;
			max-width: 450px;
			gap: 15px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
