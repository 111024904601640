.page-user-role-privileges {
	height: 100%;
	padding: 0 35px;

	@include max-width($scr-md) {
		padding: 0 15px;
	}

	.role-privileges {
		padding-bottom: 25px;

		&__form {
			width: 100%;
			max-width: 1280px;
		}

		&__container {
			margin: 15px 0;
			gap: 15px;
			display: flex;
		}

		&__name {
			margin: 0;
			color: $c-blue;
			font-size: $text-size-m;
			font-weight: $text-weight-semibold;
		}

		&__title {
			margin: 0;
			color: $c-black;
			font-weight: $text-weight-semibold;
		}

		&__box {
			flex: 1;
			padding: 15px;
			border-radius: 8px;
			background-color: $c-white;
			box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
		}

		&__box-header {
			min-height: 40px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__wrapper {
			margin-top: 10px;
			gap: 35px;
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;

			&--border {
				border-top: 1px dashed $c-disabled;
			}
		}

		&__box-body {
			width: 100%;
			flex: 1;

			.app-input {
				margin: 0 0 20px;
			}
		}

		&__button-container {
			width: 100%;
			max-width: 350px;
			margin-left: auto;
			gap: 15px;
			display: flex;

			.app-button {
				flex: 1;

				&--outline {
					width: auto;
					border: 0;
					background-color: transparent;

					.app-button__label {
						color: $c-primary;
					}
				}
			}
		}

		&__footer {
			margin: 5px 15px;
			color: $c-red;
			font-size: $text-size-xs;
			font-weight: $text-weight-normal;
		}

		.app-table {
			.table {
				td,
				th {
					&:first-child {
						width: auto;
						min-width: auto;
						max-width: none;
					}
				}

				tr {
					.table__td {
						border-radius: 0;
					}
				}

				&__thead {
					display: none;
				}

				&__tbody {
					box-shadow: none;
				}

				&__title {
					&--values {
						min-width: 100px;
						color: $c-dark-grey;
						font-size: $text-size-s;
					}
				}

				&__permissions {
					margin: 0;
					padding: 0;
					display: flex;
					flex-wrap: wrap;
				}

				&__permission {
					width: 33.33%;
					list-style-type: none;
					display: flex;

					@include max-width($scr-md) {
						width: 50%;
					}

					@include max-width($scr-sm) {
						width: 100%;
					}
				}

				&__permission-label {
					margin: 5px;
					color: $c-dark-grey;
					font-size: $text-size-xs;
				}
			}
		}

		.MuiSwitch-root {
			padding: 0;
			width: 28px;
			height: 18px;
			margin: 5px 0;
			border-radius: 100px;

			.MuiSwitch-switchBase {
				padding: 3px;
			}

			.Mui-checked {
				color: $c-primary;
				transform: translateX(10px);

				+ .MuiSwitch-track {
					opacity: 1;
					background-color: $c-primary;
				}

				.MuiSwitch-thumb {
					&:before {
						content: "";
						position: absolute;
						width: 100%;
						height: 100%;
						left: 0;
						top: 0;
						background-size: 8px;
						background-position: center;
						background-repeat: no-repeat;
						background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEwIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDQuNTA1NjJMMy4zNDMxNSA3TDkgMSIgc3Ryb2tlPSIjMDE4ODk3IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
					}
				}
			}

			.MuiSwitch-thumb {
				width: 12px;
				height: 12px;
				color: $c-white;
			}
		}
	}
}
