.app-mobile-input {
	position: relative;
	width: 100%;
	min-width: 150px;
	display: flex;

	&--disabled {
		background-color: #f8f8f8;
	}

	&__label {
		color: $c-dark-grey;
		font-size: $text-size-s;
		font-weight: $text-weight-semibold;
	}

	&__required {
		color: $c-red;
		line-height: 0;
	}

	&__icon {
		position: relative;
		width: 20px;
		height: 20px;
	}

	.MuiFormControl-root {
		width: 100%;

		.MuiInputBase-root {
			padding: 0;
			color: $c-black;
			border-radius: 0;
			font-weight: $text-weight-normal;
		}

		.MuiSelect-select {
			padding-right: 10px !important;
		}

		.MuiSelect-icon {
			top: inherit;
			right: 0;
		}

		.MuiInputBase-input {
			padding: 10px 0;

			&::placeholder {
				color: $c-light-grey;
			}
		}

		.MuiOutlinedInput-notchedOutline {
			border: 0 !important;
			border-bottom: 1px solid $c-disabled !important;
		}

		.MuiFormHelperText-root {
			width: 100%;
			margin: 3px 0 0;
			color: $c-red;
			font-weight: $text-weight-normal;
			font-size: $text-size-xs;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
}

.app-mobile-input-modal {
	//
	.MuiPaper-root {
		max-height: 300px;

		.MuiFormControl-root {
			width: 100%;

			.MuiInputBase-root {
				padding: 0;

				.app-icon {
					width: 20px;
					height: 20px;
				}
			}

			.MuiInputBase-input {
				padding: 10px 0;
			}

			.MuiOutlinedInput-notchedOutline {
				border: 0 !important;
			}
		}
	}
}
