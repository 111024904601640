.page-contact-us-messages {
	height: 100%;
	display: flex;
	padding: 0 35px 15px;

	@include max-width($scr-md) {
		padding: 0 15px;
	}

	.contact-us-messages {
		min-width: 0;
		height: 100%;
		flex: 1;

		.messages {
			height: 100%;
			min-width: 0;
			display: flex;

			.search-input {
				padding: 15px;
				background-color: $c-white;
				border-bottom: 1px solid #d3e0e8;

				.app-icon {
					width: 20px;
					height: 20px;
				}

				.app-input {
					.MuiOutlinedInput-notchedOutline {
						border-bottom: 0 !important;
					}

					.MuiFormHelperText-root {
						display: none;
					}
				}
			}

			&__container {
				width: 100%;
				overflow: hidden;
				border-radius: 10px;
				box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
				background-color: #ffffff;
				display: flex;
				flex-direction: row;
			}

			&__back-button {
				width: 20px;
				height: 20px;
				margin: 0 10px 0 0;
				border: 0;
				padding: 0;
				transform: rotate(90deg);
				background-color: transparent;
				cursor: pointer;

				@include min-width($scr-sm) {
					display: none;
				}
			}

			&__inbox {
				width: 300px;
				border-right: 1px solid #d3e0e8;
				display: flex;
				flex-shrink: 0;
				flex-direction: column;

				@include max-width($scr-sm) {
					width: 100%;

					&--hide {
						display: none;
					}
				}
			}

			&__date {
				margin: 0;
				white-space: nowrap;
				color: $c-light-grey;
				font-size: $text-size-xs;
				display: flex;
				align-items: center;
			}

			&__content {
				width: 100%;
				min-width: 25%;
				background-color: $c-white;
				display: flex;
				flex-direction: column;

				@include max-width($scr-md) {
					display: none;

					&--show {
						display: flex;
					}
				}
			}

			&__subject {
				width: 90%;
				color: $c-blue;
				font-size: $text-size-body;
				font-weight: $text-weight-semibold;
				text-align: left;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}

			&__subject-header {
				position: relative;
				height: 74px;
				margin: 0 30px;
				border-bottom: 1px solid #d3e0e8;
				display: flex;
				align-items: center;
				flex-shrink: 0;

				@include max-width($scr-sm) {
					margin: 0 10px;
				}
			}

			&__end-session {
				position: absolute;
				width: 170px;
				bottom: -15px;
				left: 0;
				right: 0;
				margin: 0 auto;
				padding: 10px 15px;
				color: $c-red;
				font-size: $text-size-xs;
				font-weight: $text-weight-normal;
				border-radius: 100px;
				border: 1px solid $c-red;
				background-color: $c-white;
				cursor: pointer;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
			}

			&__end-session-icon {
				width: 13px;
				height: 13px;
				margin: -5px 0 0 10px;
				display: block;
			}

			&__body {
				overflow-x: hidden;
				overflow-y: scroll;
				margin-bottom: auto;
			}

			.list {
				padding: 0;
				margin: 0;
				width: 100%;
				height: 100%;
				overflow-x: hidden;
				overflow-y: scroll;
				background-color: $c-background;

				&__wrapper {
					min-width: 0;
					flex: 1;
					display: flex;
					flex-direction: column;

					.app-status {
						margin-left: auto;
					}
				}

				&__item {
					padding: 10px;
					list-style-type: none;
					cursor: pointer;
					background-color: transparent;

					&:not(:first-child) {
						border-top: 1px solid $c-light-grey;
					}

					&--new {
						background-color: $c-white;
					}
				}

				&__header {
					margin: 5px 0;
					display: flex;
					align-items: flex-start;
					justify-content: space-between;
				}

				&__body {
					display: flex;
					justify-content: space-between;
				}

				&__attachement {
					width: 15px;
					height: 15px;
					margin: 0 5px 0 0;
				}

				&__name {
					margin: 0 15px 0 0;
					color: $c-dark-grey;
					font-size: $text-size-xs;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}

				&__enquiry-no {
					margin: 2.5px 0 0;
					color: $c-light-grey;
					font-size: $text-size-xxs;
				}

				&__title {
					margin: 0 0 5px;
					color: $c-blue;
					font-size: $text-size-xs;
					font-weight: $text-weight-semibold;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;

					&--new {
						display: flex;
						align-items: center;

						&::before {
							content: "";
							width: 5px;
							height: 5px;
							margin: 0 5px 0 0;
							border-radius: 10px;
							background-color: $c-red;
							display: block;
						}
					}
				}

				&__text {
					margin: 0;
					color: $c-dark-grey;
					font-size: $text-size-xxs;
				}
			}

			.history {
				margin: 0 25px;
				padding: 20px 0;

				&:last-child {
					margin-bottom: 200px;
				}

				&:not(:last-child) {
					border-bottom: 1px solid #d3e0e8;
				}

				&__header {
					padding: 0 0 10px;
					display: flex;
					align-items: center;
					justify-content: space-between;
				}

				&__content {
				}

				&__wrapper {
					display: flex;
					flex-direction: column;
				}

				&__comment {
					display: flex;
					justify-content: space-between;
				}

				&__title {
					margin: 0;
					color: $c-blue;
					font-size: $text-size-s;
					font-weight: $text-weight-semibold;
				}

				&__creator {
					margin: 0;
					color: $c-black;
					font-size: $text-size-s;
					font-weight: $text-weight-semibold;
				}

				&__download {
					margin: 0;
					color: #13a7f4;
					font-size: $text-size-s;
					font-weight: $text-weight-semibold;
					border: 0;
					background-color: transparent;
					cursor: pointer;
				}

				&__email {
					margin: 0;
					color: $c-dark-grey;
					font-size: $text-size-xs;
				}

				&__expand {
					margin: 0 0 0 25px;
					color: #13a7f4;
					white-space: nowrap;
					font-size: $text-size-xs;
					cursor: pointer;
				}

				&__attachment {
					width: 100%;
					padding: 20px 0 0;
					margin-top: 20px;
					border-top: 1px dashed #d3e0e8;
					display: none;
					flex-direction: column;

					&--show {
						display: flex;
					}
				}

				&__attachment-header {
					display: flex;
					justify-content: space-between;
				}

				&__attachment-doc {
					margin: 20px 0 0;
					display: flex;
					align-items: center;
				}

				&__file-icon {
					width: 30px;
					height: 30px;
				}

				&__file {
					margin: 0 5px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;

					a {
						color: #13a7f4;
						font-size: $text-size-xs;
						text-decoration: none;
					}
				}

				&__text {
					margin: 0;
					color: $c-dark-grey;
					font-size: $text-size-xs;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;

					&--show {
						white-space: wrap;
					}
				}
			}

			.input-bar {
				margin: 20px;
				padding: 15px;
				background-color: $c-white;
				border: 1px solid #d3e0e8;

				&__container {
					padding: 5px 25px;
					border-radius: 10px;
					background-color: $c-white;
					box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
					display: flex;
					align-items: center;
				}

				&__textarea {
					width: 100%;
					padding: 15px 15px 15px 0;
					resize: none;
					outline: none;
					border: 0;
					font-weight: 500;
					font-family: $font-family;

					&:disabled {
						background-color: transparent;
					}

					&::placeholder {
						opacity: 0.5;
						color: $c-light-grey;
					}
				}

				&__button {
					height: 40px;
					padding: 0 20px;
					border: 0;
					color: $c-white;
					border-radius: 100px;
					font-size: $text-size-s;
					font-weight: $text-weight-normal;
					background-color: $c-primary;
					transition: $transition;
					cursor: pointer;

					&:disabled {
						opacity: 0.2;
					}
				}
			}
		}
	}
}
