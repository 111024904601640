.app-endorsement-modal {
	min-width: 300px;

	.endorsement-modal {
		width: 100%;
		height: 100%;
		max-width: 560px;
		margin-left: auto;
		background-color: $c-white;

		&__main {
			width: 100%;
			height: 100%;
			padding: 25px 0;

			@include max-width($scr-xs) {
				overflow-y: scroll;
				scrollbar-width: none;
				-ms-overflow-style: none;

				&::-webkit-scrollbar {
					width: 0;
					height: 0;
				}
			}
		}

		&__header {
			padding: 0 45px;

			@include max-width($scr-xs) {
				padding: 0 35px;
			}
		}

		&__close-button {
			width: 30px;
			height: 30px;
			margin: 0 15px 15px auto;
			border: 0;
			cursor: pointer;
			background-color: transparent;
			display: block;
		}

		&__title {
			margin: 15px 0 0;
			color: $c-blue;
			font-weight: $text-weight-semibold;
		}

		&__sub-title {
			margin: 15px 0 0;
			color: $c-black;
			font-weight: $text-weight-semibold;
		}

		&__description {
			margin: 5px 0 15px;
			color: $c-dark-grey;
			font-size: $text-size-s;
		}

		&__date {
			margin: 5px 0;
			color: $c-light-grey;
			font-size: $text-size-xs;
		}

		&__amount {
			margin: 15px 0 5px;
			font-weight: $text-weight-semibold;
		}

		&__text {
			margin: 5px 0 0;
			color: $c-dark-grey;
			word-wrap: break-word;
			font-size: $text-size-s;
		}

		&__scrollable {
			padding: 0 0 15px;
			height: calc(100% - 255px);
			overflow-y: scroll;
			scrollbar-width: none;
			-ms-overflow-style: none;

			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}
		}

		&__list {
			height: 100%;
			padding: 0;
			list-style-type: none;
		}

		&__invoice {
			margin: 0 0 10px;
			padding: 15px;
			background-color: #f8f8f8;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__body {
			padding: 15px 45px;

			&--invoice {
				height: 100%;
			}
		}

		&__container {
			display: flex;
			flex-wrap: wrap;
		}

		&__info {
			width: 50%;
			margin: 10px 0;
			padding: 0 15px 0 0;

			@include max-width($scr-xs) {
				width: 100%;
			}
		}

		&__label {
			margin: 5px 0;
			color: $c-light-grey;
			font-size: $text-size-s;
			word-wrap: break-word;
		}

		&__value {
			margin: 0;
			font-size: $text-size-s;
			word-wrap: break-word;
		}

		&__divider {
			margin: 0 45px;
			border: 1px dashed $c-disabled;
		}

		&__loading {
			height: calc(100% - 250px);
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				width: 70px;
				height: 70px;

				path {
					stroke: #eaeaea;
					stroke-dasharray: 500;
					animation: loading 5s linear infinite;
				}
			}
		}

		.info {
			padding: 0 45px;
			margin: 0 0 25px;
			display: flex;
			align-items: stretch;
			gap: 10px;

			@include max-width($scr-xs) {
				padding: 0 35px;
			}

			&__box {
				flex: 1;
				padding: 20px 12.5px;
				background-color: #f8f8f8;
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;
			}
		}
	}
}
