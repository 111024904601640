.app-table {
	table-layout: fixed;
	padding: 0 0 25px;
	width: 100%;
	height: 100%;

	&__filter-column {
		padding: 0 10px;
		margin-bottom: 10px;
		display: none;

		@include max-width($scr-sm) {
			display: block;
		}
	}

	&__footer {
		margin: 15px 0;
		padding: 0 35px;
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;

		@include max-width($scr-md) {
			padding: 0 15px;
		}
	}

	&__text {
		margin: 0 10px 0 20px;
		color: $c-light-grey;
		font-size: $text-size-s;
	}

	&__pagination {
		display: flex;
		flex-direction: row;
	}

	&__page {
		.MuiInputBase-root {
			border-radius: 0;
		}

		.MuiSelect-select {
			padding: 0;
			color: $c-primary;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
		}

		.MuiOutlinedInput-notchedOutline {
			border: 0;
		}

		.MuiSelect-icon {
			width: 13px;
			height: 13px;
			top: unset;
		}
	}

	&__arrow {
		width: 28px;
		height: 28px;
		border: 0;
		cursor: pointer;
		background-color: transparent;
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			width: 100%;
		}

		&--next {
			transform: rotate(180deg);
		}
	}

	&__container {
		overflow: scroll;
	}

	.table {
		width: 100%;
		padding: 0 35px 25px;

		@include max-width($scr-md) {
			padding: 0 15px 25px;
		}

		&__thead {
		}

		&__tbody {
			border-radius: 10px;
			background-color: $c-white;
			box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
		}

		&__tr {
			&:first-child {
				.table__td {
					&:first-child {
						border-top-left-radius: 10px;
					}

					&:last-child {
						border-top-right-radius: 10px;
					}
				}
			}

			&:last-child {
				.table__td {
					&:first-child {
						border-bottom-left-radius: 10px;
					}

					&:last-child {
						border-bottom-right-radius: 10px;
					}
				}
			}

			&:nth-child(even) {
				background-color: #f8f8f8;
			}
		}

		&__th {
			min-width: 100px;
			padding: 20px 15px;
			color: $c-dark-grey;
			text-align: left;
			white-space: nowrap;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;

			&--action,
			&--selection {
				width: 50px;
				max-width: 50px;
				min-width: 50px;
				padding: 0;
				text-align: center;
			}

			&--indexing {
				width: 60px;
				max-width: 60px;
				min-width: 60px;
				padding: 0;
				text-align: center;
			}
		}

		&__td {
			padding: 20px;
			min-width: 100px;
			color: $c-dark-grey;
			word-break: break-word;
			font-size: $text-size-s;
			vertical-align: baseline;

			&--action,
			&--selection {
				width: 50px;
				max-width: 50px;
				min-width: 50px;
				padding: 0;
				text-align: center;
			}

			&--indexing {
				width: 60px;
				max-width: 60px;
				min-width: 60px;
				padding: 20px 0;
				text-align: center;
			}
		}

		&__left {
			&--values {
				text-align: left;
				color: $c-dark-grey;
				font-size: $text-size-s;
				white-space: pre-line;
			}
		}

		&__center {
			&--values {
				text-align: center;
				color: $c-dark-grey;
				font-size: $text-size-s;
				white-space: pre-line;
			}
		}

		&__right {
			&--values {
				text-align: right;
				color: $c-dark-grey;
				font-size: $text-size-s;
				white-space: pre-line;
			}
		}

		&__sortby {
			width: 12.5px;
			height: 12.5px;
			margin: 0 5px;
			vertical-align: middle;
			transform: rotate(180deg);

			&--desc {
				transform: rotate(0);
			}

			&--inactive {
				opacity: 0.2;
				transform: rotate(0);
			}
		}

		&__action {
			border: 0;
			cursor: pointer;
			background-color: transparent;
			flex-shrink: 0;

			img {
				pointer-events: none;
			}
		}

		&__checkbox {
			width: 20px;
			height: 20px;
			margin: 0 auto;
			border-radius: 5px;
			background-color: transparent;
			border: 1px solid $c-light-grey;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				flex-shrink: 0;

				path {
					stroke-width: 3px;
				}
			}

			&--active,
			&--indeterminate {
				border: 1px solid $c-blue;
				background-color: $c-blue;
			}
		}

		&__check-icon {
			margin: 1px 0 0;
			padding: 2px;
			flex-shrink: 0;
		}
	}
}
