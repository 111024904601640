.app-retrieve-payment-status-modal {
	min-width: 300px;
	padding: 10px;
	display: flex;

	.retrieve-payment-status {
		width: 100%;
		max-width: 560px;
		margin: auto;
		padding: 50px;
		border-radius: 8px;
		background-color: $c-white;

		&__header {
		}

		&__title {
			margin: 0;
			color: $c-primary;
			font-size: $text-size-l;
			font-weight: $text-weight-semibold;
		}

		&__list {
			padding: 0;
		}

		&__item {
			padding: 20px 15px;
			margin: 0 0 10px;
			background-color: #f8f8f8;
			list-style-type: none;
			gap: 15px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__label {
			margin: 0;
			color: $c-dark-grey;
			font-size: $text-size-s;
			flex: 1;
		}

		&__value {
			margin: 0;
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
			flex: 1;
		}

		&__button-container {
			max-width: 250px;
			margin: 25px auto 0;
		}
	}
}
