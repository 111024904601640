.app-download-policy-modal {
	margin: 15px;
	display: flex;
	align-items: center;
	justify-content: center;

	.download-policy {
		width: 100%;
		max-width: 560px;
		border-radius: 10px;
		background-color: $c-white;
		outline: none;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		&__header {
			padding: 50px 35px 0;
		}

		&__title {
			margin: 0;
			color: $c-primary;
			font-size: $text-size-xl;
			font-weight: $text-weight-semibold;
		}

		&__description {
			color: $c-dark-grey;
		}

		&__list-header {
			position: sticky;
			top: 0;
			padding: 0 0 15px;
			color: $c-black;
			background-color: $c-white;
			font-weight: $text-weight-normal;
			display: flex;
			justify-content: flex-end;

			.app-checkbox {
				margin: 0 0 0 10px;

				&__box {
					border: 1px solid $c-light-grey;
				}

				&__label {
					display: none;
				}
			}
		}

		&__list {
			margin: 0;
			padding: 0 35px;
			max-height: 300px;
			overflow-y: scroll;
		}

		&__item {
			margin: 10px 0;
			color: $c-dark-grey;
			font-size: $text-size-xs;
			list-style-type: none;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.app-checkbox {
				&__box {
					border: 1px solid $c-light-grey;
				}

				&__label {
					display: none;
				}
			}
		}

		&__loading {
			min-height: 250px;
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				width: 70px;
				height: 70px;

				path {
					stroke: #eaeaea;
					stroke-dasharray: 500;
					animation: loading 5s linear infinite;
				}
			}
		}

		&__category {
			margin: 15px 0 0;
			padding: 25px 0 0;
			color: $c-dark-grey;
			font-weight: $text-weight-semibold;
			border-top: 1px dashed $c-disabled;

			~ .download-policy__category {
				margin: 25px 0 0;
			}
		}

		&__progress-bar {
			width: 0;
			height: 2px;
			background-color: $c-green;
			transition: $transition;
		}

		&__button-container {
			margin: 25px 0 0;
			padding: 0 35px 50px;
			gap: 15px;
			display: flex;
			align-items: center;
			justify-content: center;

			.app-button {
				&--outline {
					border: 0;

					.app-button__label {
						color: $c-primary;
					}
				}
			}
		}
	}
}
