.app-tabs {
	//
	.tabs {
		width: 100%;
		margin: 0 0 15px;
		padding: 0;
		border-bottom: 1px solid $c-disabled;
		display: flex;

		&__tab {
			position: relative;
			padding: 15px;
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-normal;
			list-style-type: none;
			cursor: pointer;

			&::before {
				content: "";
				position: absolute;
				width: 100%;
				height: 3px;
				left: 0;
				right: 0;
				bottom: -2px;
				opacity: 0;
				border-radius: 10px;
				transition: all 0.25s ease-in;
			}

			&--active {
				color: $c-blue;
				font-weight: $text-weight-semibold;

				&::before {
					opacity: 1;
					background-color: $c-green;
				}
			}
		}
	}
}
