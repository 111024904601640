.page-amendment-request {
	height: 100%;
	padding: 0 35px;

	@include max-width($scr-md) {
		padding: 0 15px;
	}

	.amendment-request {
		padding-bottom: 25px;

		&__form {
			width: 100%;
			max-width: 1280px;
		}

		&__container {
			margin: 15px 0;
			gap: 15px;
			display: flex;
		}

		&__name {
			margin: 0;
			color: $c-blue;
			font-size: $text-size-m;
			font-weight: $text-weight-semibold;
		}

		&__title {
			margin: 0 0 25px;
			padding: 0 0 15px;
			color: $c-black;
			font-weight: $text-weight-semibold;
			border-bottom: 1px dashed $c-disabled;
		}

		&__list {
			padding: 0;
			margin: 0;
			gap: 5px;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: space-between;
		}

		&__item {
			width: calc(50% - 15px);
			list-style-type: none;
			gap: 10px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		&__label {
			color: $c-dark-grey;
			word-break: break-all;
			font-size: $text-size-s;
			flex: 1;
		}

		&__value {
			color: $c-black;
			text-align: right;
			word-break: break-all;
			font-size: $text-size-s;
			flex: 1;
		}

		&__box {
			flex: 1;
			padding: 15px;
			border-radius: 8px;
			background-color: $c-white;
			box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
			display: flex;
			flex-direction: row;
		}

		&__box-body {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			gap: 20px 35px;

			.app-select-input {
				@include min-width($scr-sm) {
					width: calc(50% - 17.5px);
				}
			}
		}

		&__wrapper {
			flex: 1;
		}

		&__table {
		}

		&__table-header {
			width: 100%;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__table-header-cell {
			position: relative;
			height: 100%;
			flex: 1;
			display: flex;
			align-items: center;
		}

		&__table-body {
			width: 100%;
			margin: 10px 0 0;
			background-color: #f8f8f8;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&:nth-child(odd) {
				background-color: $c-white;
			}
		}

		&__table-title {
			margin: 0;
			padding: 15px;
			color: $c-dark-grey;
			word-break: break-word;
			font-size: $text-size-xs;
			font-weight: $text-weight-semibold;
			flex: 1;
		}

		&__table-file-size {
			margin: 0;
			padding: 15px;
			text-align: right;
			color: $c-dark-grey;
			font-size: $text-size-xs;
			font-weight: $text-weight-semibold;
			flex: 1;
		}

		&__table-label {
			margin: 0;
			padding: 15px;
			color: $c-dark-grey;
			word-break: break-word;
			font-size: $text-size-xs;
			flex: 1;
		}

		&__table-link {
			margin: 0;
			padding: 15px;
			color: #13a7f4;
			word-break: break-word;
			cursor: pointer;
			font-size: $text-size-xs;
			flex: 1;
		}

		&__table-value {
			margin: 0;
			padding: 15px;
			color: $c-black;
			word-break: break-word;
			font-size: $text-size-xs;
			font-weight: $text-weight-semibold;
			flex: 1;
		}

		&__table-body-cell {
			position: relative;
			flex: 1;
			display: flex;
			align-items: center;

			.amendment-request__table-file-size {
				font-weight: $text-weight-regular;
			}
		}

		&__button-container {
			width: 100%;
			max-width: 350px;
			margin-left: auto;
			gap: 15px;
			display: flex;

			.app-button {
				flex: 1;

				&--outline {
					width: auto;
					border: 0;
					background-color: transparent;

					.app-button__label {
						color: $c-primary;
					}
				}
			}
		}
	}
}
