.app-policy-motor-modal {
	min-width: 300px;

	.policy-motor-modal {
		width: 100%;
		height: 100%;
		max-width: 560px;
		margin-left: auto;
		padding: 45px 0 0;
		background-color: $c-white;

		&__main {
			width: 100%;
			height: 100%;

			@include max-width($scr-xs) {
				overflow-y: scroll;
				scrollbar-width: none;
				-ms-overflow-style: none;

				&::-webkit-scrollbar {
					width: 0;
					height: 0;
				}
			}
		}

		&__header {
			padding: 0 45px;

			@include max-width($scr-xs) {
				padding: 0 35px;
			}
		}

		&__profile {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.enquiry-motor-modal__text {
				color: $c-dark-grey;
			}
		}

		&__close-button {
			position: absolute;
			top: 10px;
			right: 10px;
			width: 30px;
			height: 30px;
			border: 0;
			cursor: pointer;
			background-color: transparent;
		}

		.info {
			padding: 0 45px;
			margin: 25px 0;
			display: flex;
			align-items: stretch;
			gap: 10px;

			@include max-width($scr-xs) {
				padding: 0 35px;
			}

			&__box {
				flex: 1;
				padding: 20px 12.5px;
				background-color: #f8f8f8;
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;

				.enquiry-motor-modal__text {
					margin: 0 0 10px;
					text-align: center;
					white-space: break-all;
				}

				.enquiry-motor-modal__title {
					text-align: center;
					word-break: break-all;
				}
			}
		}

		&__tabs {
			margin: 20px 45px 0;
			border-bottom: 1px solid $c-disabled;
			display: flex;

			@include max-width($scr-xs) {
				margin: 20px 35px 0;
			}
		}

		&__tab {
			position: relative;
			padding: 12.5px 25px;
			color: $c-dark-grey;
			font-size: $text-size-s;
			border: 0;
			cursor: pointer;
			transition: $transition;
			background-color: transparent;
			flex-grow: 1;

			&--active {
				color: $c-blue;
				font-weight: $text-weight-semibold;

				&:after {
					content: "";
					position: absolute;
					bottom: -1.5px;
					left: 0;
					right: 0;
					display: block;
					width: 100%;
					height: 2px;
					background-color: $c-green;
				}
			}
		}

		&__body {
			height: calc(100% - 250px);
			padding: 25px 45px;
			overflow-y: scroll;
			scrollbar-width: none;
			-ms-overflow-style: none;

			&--activity {
				padding: 0 45px;
			}

			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}

			@include max-width($scr-xs) {
				height: auto;
				overflow: hidden;
				padding: 25px 35px;

				&--activity {
					padding: 0 45px;
				}
			}
		}

		&__container {
		}

		&__title {
			margin: 15px 0;
			font-weight: $text-weight-semibold;
		}

		&__wrapper {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			border-bottom: 1px dashed $c-disabled;
		}

		&__info {
			width: calc(50% - 7.5px);
			margin: 0 0 15px;

			@include max-width($scr-xs) {
				width: 100%;
			}
		}

		&__label {
			margin: 0 0 5px;
			color: $c-light-grey;
			word-break: break-word;
			font-size: $text-size-s;

			&--bold {
				color: $c-black;
				font-weight: $text-weight-semibold;
			}
		}

		&__value {
			margin: 0;
			word-break: break-word;
			font-size: $text-size-s;
		}

		&__text {
			margin: 5px 0 0;
			color: $c-light-grey;
			word-break: break-word;
			font-size: $text-size-xs;
		}

		&__plan-coverage {
			border-top: 1px dashed $c-disabled;
		}

		&__row {
			width: 100%;
			margin-bottom: 15px;
			display: flex;
			justify-content: space-between;

			.enquiry-motor-modal {
				&__label {
					color: $c-dark-grey;
				}

				&__value {
					text-align: right;
				}
			}
		}

		&__total {
			margin: 0;
			text-align: right;
			color: $c-dark-grey;
			font-size: $text-size-s;

			&--bold {
				font-weight: $text-weight-semibold;
			}
		}

		&__footer {
			display: flex;
			justify-content: flex-end;
		}

		&__download-button {
			margin: 15px 0 15px auto;
			padding: 10px 20px;
			color: $c-blue;
			font-size: $text-size-s;
			text-decoration: none;
			border-radius: 10px;
			border: 1px solid $c-blue;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;

			&--disabled {
				color: $c-disabled;
				border: 1px solid $c-disabled;
				cursor: default;

				svg {	
					path {
						stroke: $c-disabled !important;
					}
				}
			}

			svg {
				transform: rotate(180deg);

				path {
					stroke: $c-blue;
				}
			}
		}

		.box {
			margin: 20px 0;
			padding: 20px 0;
			border-radius: 10px;
			border: 1px solid $c-disabled;

			&__container {
			}

			&__list {
				padding: 10px 20px;
				display: flex;
				justify-content: space-between;
			}

			&__label {
				margin: 0;
				color: $c-dark-grey;
				font-size: $text-size-s;
			}

			&__text {
				margin: 0;
				color: $c-dark-grey;
				font-size: $text-size-s;
			}
		}
	}
}
