.app-line-chart {
	width: 100%;
	height: 100%;
	font-family: $font-family;
	flex: 1;
	display: flex;
	flex-direction: column;

	.line-chart {
		width: 100%;
		height: 350px;
		display: flex;
		align-items: flex-end;

		&__header {
			padding: 10px 0;
			border-bottom: 1px solid $c-light-grey;
		}

		&__tooltips {
			position: relative;
			margin: 5px 0;
			padding: 15px 20px;
			border-radius: 10px;
			background-color: $c-white;
			box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
		}

		&__list {
			padding: 0;
		}

		&__item {
			margin: 10px 0;
			gap: 10px;
			display: flex;
			align-items: flex-end;
			justify-content: space-between;

			&:first-child {
				margin-bottom: 20px;
			}
		}

		&__date {
			margin: 0;
			color: $c-dark-grey;
		}

		&__title {
			margin: 0;
			color: #56d8e0;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
		}

		&__total {
			margin: 0;
			color: $c-blue;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
			display: flex;
			flex-direction: column;

			span {
				text-align: right;
				color: $c-dark-grey;
				font-size: $text-size-xs;
			}
		}

		&__label {
			margin: 0;
			color: $c-dark-grey;
			font-size: $text-size-s;
		}

		&__value {
			margin: 0;
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
		}

		&__summary {
			color: $c-primary;
			padding: 10px 15px;
			border-radius: 50px;
			font-size: $text-size-xs;
			background-color: $c-white;
			border: 1px solid $c-primary;
			cursor: pointer;
			display: flex;

			@include max-width($scr-md) {
				margin-bottom: 10px;
			}
		}

		&__icon {
			margin: 0 5px;
			width: 15px;
			height: 15px;
			transform: rotate(-90deg);
		}

		&__legends {
			margin: 15px 0 0;
			padding: 15px 15px 0;

			@include min-width($scr-md) {
				display: flex;
				flex-direction: row;
				justify-content: center;
			}
		}

		&__legend {
			margin: 0 10px;
			list-style-type: none;
			font-size: $text-size-xs;
			cursor: pointer;
			display: flex;
			align-items: center;

			@include max-width($scr-md) {
				margin: 5px 0;
			}
		}

		&__legend-indicator {
			display: block;
			margin: -1px 5px 0;
			width: 10px;
			height: 10px;
			border-radius: 10px;
			background-color: $c-light-grey;
		}
	}
}
